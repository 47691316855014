import Home from "../components/home-page";
import { fetchHomePageData } from "../utils/api";

export default function Index({ homePage }) {
  return (
    <div>
      <Home homePage={homePage} />
    </div>
  );
}

export async function getServerSideProps(props) {
  const { req } = props;
  const isServer = req ? true : false;
  const platform = isServer ? "server" : "client";
  const homePage = await fetchHomePageData(platform);
  return {
    props: {
      homePage,
    },
  };
}
