import dynamic from "next/dynamic";
import Link from "next/link";
import { Suspense } from "react";
const Banner = dynamic(() => import("../layout/banner"), {
  suspense: true,
});

const Index = ({ homePage }) => {
  const {
    banner,
    inviteToAdd,
    explorePlace,
    whyUs,
    mostLikedplaces,
    stateWise,
  } = homePage;
  // Construct the mailto link with subject and body
  const mailtoLink = (data) => {
    const sub =
      data === "Place"
        ? "Register my new Place"
        : data === "Travel Agency"
        ? "Register my Travel Agency"
        : "Register me as a guide";
    const subject = encodeURIComponent(sub);
    const body = encodeURIComponent("send your details");
    let temp = "";
    temp = `mailto:traviya.travel@gmail.com?subject=${subject}&body=${body}`;
    return temp;
  };
  return (
    <main className="main-content">
      <div className="text-center">
        <Banner
          heading={banner?.heading}
          content={banner?.content}
          img={banner?.img}
        />
      </div>
      <div className="main-wrapper pt-[26px]">
        <article className="mb-[40px]">
          <h3 className="text-center text-[#3E0904] text-[36px] mb-[28px]">
            {explorePlace?.heading}
          </h3>
          <div className="text-center lg:text-[20px] tracking-[0.5px]">
            {explorePlace?.description}
          </div>
          <Link
            tabindex="0"
            href="/trip"
            className="card-heading link text-center flex font-medium relative mt-[40px] max-w-fit px-[38px] py-[8px] mx-[auto] mt-[16px] lg:mt-[22px] rounded-[4px] bg-[#58A4AE] text-[#fff] uppercase text-[16px] lg:text-[18px]"
          >
            {explorePlace?.label}
          </Link>
        </article>
      </div>
      <Suspense fallback={<div>Loading...</div>}>
        <article className="bg-[rgba(177, 225, 249, 0.4)] bg-left-bottom bg-contain pb-[18px]">
          <div className="main-wrapper">
            <h3 className="text-center text-[36px] mb-[28px] pt-[28px]">
              {mostLikedplaces?.heading}
            </h3>
            <div className="row justify-center">
              {(mostLikedplaces?.data || []).map((item, i) => {
                return (
                  <div key={i} className="col-3">
                    <Link href={item?.link}>
                      <div className="bg-[#fff] mb-[24px] rounded-[8px] overflow-hidden  md:h-[386px]">
                        <div className="max-h-[230px] md:max-h-none max-h-min md:max-h-none">
                          <img
                            className="h-[230px] md:w-full w-full"
                            src={item?.img}
                          />
                        </div>
                        <div className="px-[8px] py-[8px]">
                          <div className="title text-[22px] text-[#3E0904] border-b-[2px] border-[#58A4AE] pb-[4px] mb-[4px]">
                            {item.placeName}
                          </div>
                          <div className="title max-w-[360px] md:max-w-none line-clamp-2">
                            {item.description}
                          </div>
                        </div>
                      </div>
                    </Link>
                  </div>
                );
              })}
            </div>
          </div>
        </article>
      </Suspense>
      <Suspense fallback={<div>Loading...</div>}>
        <article className="pb-[40px] bg-[#fff]">
          <div className="main-wrapper">
            <h3 className="text-center text-[36px] mb-[28px] pt-[28px]">
              {stateWise?.heading}
            </h3>
            <div className="text-center lg:text-[20px] tracking-[0.5px]">
              {stateWise?.description}
            </div>
            {/* <Link
              href={stateWise?.link}
              className="card-heading link text-center flex font-medium relative mt-[40px] max-w-fit px-[38px] py-[8px] mx-[auto] mt-[16px] lg:mt-[22px] rounded-[4px] bg-[#58A4AE] text-[#fff] uppercase text-[16px] lg:text-[18px]"
            >
              {stateWise?.label}
            </Link> */}
          </div>
        </article>
      </Suspense>
      <Suspense fallback={<div>Loading...</div>}>
        <article className="blue-gradient pb-[28px]">
          <div className="main-wrapper">
            <h3 className="text-center text-[36px] mb-[28px] pt-[28px]">
              {inviteToAdd?.title}
            </h3>
            <div className="row mb-[18px] justify-center px-[12px] lg:justify-evenly">
              {(inviteToAdd?.cards || []).map((card, i) => {
                return (
                  <div
                    key={i}
                    className="bg-[#fff] rounded-[12px] px-[16px] py-[16px] text-center flex flex-wrap items-end justify-stretch mb-[32px] lg:mb-[0] flex-[0_0_100%] lg:flex-[0_0_30.33%]"
                  >
                    <h4 className="text-center flex-[0_0_100%] text-[#58a4ae] self-baseline border-b-2 border-[#58a4ae]">
                      {card?.title}
                    </h4>
                    <article className="descriptive self-baseline leading-normal text-[18px]">
                      {card?.descriptive}
                    </article>
                    <a
                      href={mailtoLink(card.label)}
                      className="card-heading link text-center flex font-medium relative mt-[40px] max-w-fit px-[38px] py-[8px] mx-[auto] mt-[16px] lg:mt-[22px] rounded-[4px] bg-[#58A4AE] text-[#fff] uppercase text-[16px] lg:text-[18px]"
                    >
                      {card.label}
                    </a>
                  </div>
                );
              })}
            </div>
          </div>
        </article>
      </Suspense>
      <Suspense fallback={<div>Loading...</div>}>
        <article className="pb-[28px] bg-[#fff]">
          <h3 className="text-center text-[#3E0904] text-[36px] mb-[28px] pt-[28px]">
            {whyUs?.heading}
          </h3>
          <div className="main-wrapper">
            <div className="row">
              {whyUs?.data?.map((item, i) => {
                return (
                  <div key={i} className="col-6">
                    <div className="border border-[#C6E7F8] mb-[24px] rounded-[8px] px-[12px] py-[8px] min-h-min md:min-h-[200px]">
                      <div className="left"></div>
                      <div className="right">
                        <div className="title text-[22px] mb-[12px] md:mb-[0] text-[#58A4AE]">
                          {item?.why_title}
                        </div>
                        <div className="">{item?.why_description}</div>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </article>
      </Suspense>
    </main>
  );
};

export default Index;
